var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex justify-end items-center lang-switcher-wapper" },
    [
      _c(
        "button",
        { staticClass: "lang-switcher-button w-full flex items-center" },
        [
          _c("img", {
            staticClass: "w-6",
            attrs: { src: _vm.localeImage[_vm.$i18n.locale], alt: "" },
          }),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.$i18n.locales.find((el) => el.code === _vm.locale).name
              )
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("nav", { staticClass: "none-active nav" }, [
        _c(
          "ul",
          _vm._l(_vm.$i18n.locales, function (lang, index) {
            return _c(
              "li",
              {
                key: index,
                on: {
                  click: function ($event) {
                    return _vm.onChange(lang)
                  },
                },
              },
              [
                _c(
                  "button",
                  { staticClass: "w-6 h-6 flex items-center gap-1.5" },
                  [
                    _c("img", {
                      attrs: { src: _vm.localeImage[lang.code], alt: "" },
                    }),
                    _vm._v(_vm._s(lang.name)),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }