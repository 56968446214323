var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      {
        staticClass: "social bg-green-900",
        class: _vm.socialOpen ? "open" : "",
      },
      [
        _c("div", { staticClass: "social__telegram" }, [
          _c(
            "div",
            {
              staticClass: "unvisible",
              on: {
                click: function ($event) {
                  _vm.socialOpen = !_vm.socialOpen
                },
              },
            },
            [
              _c(
                "svg",
                {
                  class: _vm.socialOpen ? "arrow-rotate" : "",
                  attrs: {
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M11.9997 10.8284L7.04996 15.7782L5.63574 14.364L11.9997 8L18.3637 14.364L16.9495 15.7782L11.9997 10.8284Z",
                      fill: "white",
                    },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            { attrs: { href: "https://t.me/jasilawil", target: "_blank" } },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M12.001 22C6.47813 22 2.00098 17.5228 2.00098 12C2.00098 6.47715 6.47813 2 12.001 2C17.5238 2 22.001 6.47715 22.001 12C22.001 17.5228 17.5238 22 12.001 22ZM8.89113 13.1708L8.90378 13.1628C9.48351 15.0767 9.77337 16.0337 9.77337 16.0337C9.88564 16.3442 10.04 16.3996 10.2273 16.3743C10.4145 16.3489 10.5139 16.2476 10.6361 16.1297C10.6361 16.1297 11.0324 15.7472 11.825 14.9823L14.3759 16.8698C14.8407 17.1266 15.1763 16.9941 15.2917 16.4377L16.9495 8.61641C17.1325 7.88842 16.8115 7.59644 16.247 7.82754L6.51397 11.5871C5.84996 11.854 5.85317 12.2255 6.39308 12.3911L8.89113 13.1708Z",
                      fill: "white",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "social__item" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.linkedin.com/company/94823471",
                target: "_blank",
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M20.9109 21.2242V21.2235H20.9153V14.7687C20.9153 11.6109 20.2355 9.17847 16.5439 9.17847C14.7692 9.17847 13.5783 10.1523 13.0921 11.0756H13.0407V9.47327H9.54053V21.2235H13.1852V15.4052C13.1852 13.8733 13.4756 12.3919 15.3727 12.3919C17.242 12.3919 17.2699 14.1402 17.2699 15.5035V21.2242H20.9109Z",
                      fill: "#E3E3E3",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M3.49121 9.47412H7.14028V21.2243H3.49121V9.47412Z",
                      fill: "#E3E3E3",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M5.31366 3.33179C4.14693 3.33179 3.2002 4.27852 3.2002 5.44526C3.2002 6.61199 4.14693 7.57852 5.31366 7.57852C6.4804 7.57852 7.42713 6.61199 7.42713 5.44526C7.4264 4.27852 6.47966 3.33179 5.31366 3.33179V3.33179Z",
                      fill: "#E3E3E3",
                    },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: "https://twitter.com/GggiUzbekistan",
                target: "_blank",
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c(
                    "mask",
                    {
                      staticStyle: { "mask-type": "alpha" },
                      attrs: {
                        id: "mask0_20005263_4383",
                        maskUnits: "userSpaceOnUse",
                        x: "2",
                        y: "3",
                        width: "20",
                        height: "21",
                      },
                    },
                    [
                      _c("rect", {
                        attrs: {
                          x: "2.21191",
                          y: "3.81177",
                          width: "19.2",
                          height: "19.2",
                          fill: "#C4C4C4",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("g", { attrs: { mask: "url(#mask0_20005263_4383)" } }, [
                    _c("path", {
                      attrs: {
                        d: "M21.4119 7.45862C20.6979 7.77182 19.9371 7.97942 19.1439 8.08022C19.9599 7.59302 20.5827 6.82742 20.8755 5.90462C20.1147 6.35822 19.2747 6.67862 18.3795 6.85742C17.6571 6.08822 16.6275 5.61182 15.5043 5.61182C13.3251 5.61182 11.5707 7.38062 11.5707 9.54902C11.5707 9.86102 11.5971 10.161 11.6619 10.4466C8.38951 10.287 5.49391 8.71862 3.54871 6.32942C3.20911 6.91862 3.00991 7.59302 3.00991 8.31902C3.00991 9.68222 3.71191 10.8906 4.75831 11.5902C4.12591 11.5782 3.50551 11.3946 2.97991 11.1054C2.97991 11.1174 2.97991 11.133 2.97991 11.1486C2.97991 13.0614 4.34431 14.6502 6.13351 15.0162C5.81311 15.1038 5.46391 15.1458 5.10151 15.1458C4.84951 15.1458 4.59511 15.1314 4.35631 15.0786C4.86631 16.6374 6.31351 17.7834 8.03431 17.8206C6.69511 18.8682 4.99471 19.4994 3.15391 19.4994C2.83111 19.4994 2.52151 19.485 2.21191 19.4454C3.95551 20.5698 6.02191 21.2118 8.25031 21.2118C15.4935 21.2118 19.4535 15.2118 19.4535 10.011C19.4535 9.83702 19.4475 9.66902 19.4391 9.50222C20.2203 8.94782 20.8767 8.25542 21.4119 7.45862Z",
                        fill: "#E3E3E3",
                      },
                    }),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }