var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-1 pb-4" }, [
    _c(
      "div",
      {
        staticClass:
          "flex justify-between items-start py-2 px-1 rounded-t border-b dark:border-gray-600",
      },
      [
        _c(
          "button",
          {
            staticClass:
              "text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white",
            attrs: { type: "button", "data-modal-toggle": "defaultModal" },
            on: {
              click: function ($event) {
                return _vm.onClose("canceled")
              },
            },
          },
          [
            _c(
              "svg",
              {
                staticClass: "w-5 h-5",
                attrs: {
                  fill: "currentColor",
                  viewBox: "0 0 20 20",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
                    "clip-rule": "evenodd",
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "h3",
          {
            staticClass:
              "text-xl ml-2 font-semibold text-gray-900 lg:text-2xl dark:text-white",
          },
          [_vm._v("\n        " + _vm._s(_vm.$t("send-photo")) + "\n      ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "text-green-700 bg-green-100 hover:bg-green-200 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white",
            attrs: { type: "button", "data-modal-toggle": "defaultModal" },
            on: {
              click: function ($event) {
                return _vm.send()
              },
            },
          },
          [_vm._v("\n        " + _vm._s(_vm.$t("send")) + "\n      ")]
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticStyle: { position: "relative" } }, [
      _c("div", { staticClass: "image_placeholder mb-2" }, [
        _vm.image
          ? _c("img", {
              attrs: { src: _vm.$tools.getFileUrl(_vm.image), alt: "image" },
            })
          : _c(
              "div",
              { staticStyle: { "font-size": "144px", "font-weight": "100" } },
              [_c("i", { staticClass: "bx bx-image" })]
            ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "border-t-2 border-gray-200 px-4 pt-4 mb-2 sm:mb-0" },
      [
        _c("div", { staticClass: "relative flex" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.text,
                expression: "text",
              },
            ],
            staticClass:
              "w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 bg-gray-200 rounded-md p-3",
            attrs: { rows: 1, placeholder: _vm.$t("write-a-message") },
            domProps: { value: _vm.text },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.send()
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.text = $event.target.value
              },
            },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }