var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "flex", attrs: { "aria-label": "Breadcrumb" } },
    [
      _c(
        "ol",
        { staticClass: "flex items-center space-x-4" },
        [
          _c("li", [
            _c(
              "div",
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "text-gray-400 hover:text-gray-500",
                    attrs: { to: { path: "/" } },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "flex-shrink-0 h-5 w-5",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 20 20",
                          fill: "currentColor",
                          "aria-hidden": "true",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "sr-only" }, [_vm._v("Home")]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm._l(_vm.items, function (item) {
            return _c("li", { key: item.text }, [
              _c(
                "div",
                { staticClass: "flex items-center" },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "flex-shrink-0 h-5 w-5 text-gray-400",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 20 20",
                        fill: "currentColor",
                        "aria-hidden": "true",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          d: "M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z",
                          "clip-rule": "evenodd",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "NuxtLink",
                    {
                      staticClass:
                        "ml-4 text-sm font-medium text-gray-500 hover:text-gray-700",
                      attrs: {
                        disabled: item.disabled,
                        to: { path: item.link },
                      },
                    },
                    [_vm._v(_vm._s(item.text))]
                  ),
                ],
                1
              ),
            ])
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }