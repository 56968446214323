var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c("div", { staticClass: "relative w-full mb-1" }, [
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.minprice,
              expression: "minprice",
            },
          ],
          staticClass:
            "absolute pointer-events-none appearance-none z-20 h-1.5 w-full opacity-0 cursor-pointer",
          attrs: { type: "range", step: _vm.step, min: _vm.min, max: _vm.max },
          domProps: { value: _vm.minprice },
          on: {
            input: _vm.mintrigger,
            __r: function ($event) {
              _vm.minprice = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.maxprice,
              expression: "maxprice",
            },
          ],
          staticClass:
            "absolute pointer-events-none appearance-none z-20 h-1.5 w-full opacity-0 cursor-pointer",
          attrs: { type: "range", step: _vm.step, min: _vm.min, max: _vm.max },
          domProps: { value: _vm.maxprice },
          on: {
            input: _vm.maxtrigger,
            __r: function ($event) {
              _vm.maxprice = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "relative z-10 h-1.5" }, [
          _c("div", {
            staticClass:
              "absolute z-10 left-0 right-0 bottom-0 top-0 rounded-md bg-gray-200",
          }),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "absolute z-20 top-0 bottom-0 rounded-md bg-indigo-500",
            style: "right:" + _vm.maxthumb + "%; left:" + _vm.minthumb + "%",
          }),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "absolute z-30 w-4 h-4 border-2 border-indigo-500 top-0 left-0 bg-white rounded-full",
            staticStyle: { "margin-top": "-0.3rem" },
            style: "left: " + _vm.minthumb + "%",
          }),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "absolute z-30 w-4 h-4 border-2 border-indigo-500 top-0 right-0 bg-white rounded-full",
            staticStyle: { "margin-top": "-0.3rem" },
            style: "right: " + _vm.maxthumb + "%",
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex justify-center" }, [
      _c("p", { staticClass: "text-gray-500" }, [
        _c("span", [_vm._v(_vm._s(_vm._f("currency")(_vm.minprice)))]),
        _vm._v(" -\n        "),
        _c("span", [_vm._v(_vm._s(_vm._f("currency")(_vm.maxprice)))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }