var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lg:relative" },
    [
      _c(
        "div",
        { ref: "trigger" },
        [
          _vm._t("trigger", null, {
            isOpen: _vm.isOpen,
            onToggle: _vm.toggle,
            close: _vm.close,
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class": "transition ease-out duration-100",
            "enter-class": "ransform opacity-0 scale-95",
            "enter-to-class": "transform opacity-100 scale-100",
            "leave-active-class": "transition ease-in duration-75",
            "leave-class": "transform opacity-100 scale-100",
            "leave-to-class": "ransform opacity-0 scale-95",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isOpen,
                  expression: "isOpen",
                },
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.close,
                  expression: "close",
                },
              ],
              staticClass:
                "origin-top-right absolute z-10 inset-x-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none",
              class: `${
                _vm.align === "left" ? "left-0" : "lg:left-auto lg:right-0"
              } ${_vm.menuSize}`,
              attrs: {
                role: "menu",
                "aria-orientation": "vertical",
                "aria-labelledby": "options-menu",
              },
            },
            [
              _c(
                "div",
                { staticClass: "px-3" },
                [_vm._t("default", null, { close: _vm.close })],
                2
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }