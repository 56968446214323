var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "swiper",
          rawName: "v-swiper",
          value: _vm.sliderOptions,
          expression: "sliderOptions",
        },
      ],
      staticClass: "swiper",
      attrs: { loadtheme: false },
    },
    [
      _c(
        "div",
        { staticClass: "swiper-wrapper" },
        [
          _vm._t("pre-slide"),
          _vm._v(" "),
          _vm._l(_vm.items, function (item, index) {
            return _c(
              "div",
              { key: `slide-${index}`, staticClass: "swiper-slide" },
              [_vm._t("default", null, { item: item })],
              2
            )
          }),
          _vm._v(" "),
          _vm._t("post-slide"),
        ],
        2
      ),
      _vm._v(" "),
      _vm.showNavigation
        ? _c("div", {
            staticClass: "swiper-button-prev",
            attrs: { slot: "button-prev" },
            slot: "button-prev",
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showNavigation
        ? _c("div", {
            staticClass: "swiper-button-next",
            attrs: { slot: "button-next" },
            slot: "button-next",
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }