var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "group w-full hover:shadow-md shadow-sm border border-gray-100 rounded-md relative",
    },
    [
      _c(
        "div",
        {
          staticClass: "flex items-center w-full bg-white rounded-md p-2 gap-2",
        },
        [
          _vm.data &&
          _vm.data.attributes &&
          _vm.data.attributes.product &&
          _vm.data.attributes.product.data &&
          _vm.data.attributes.product.data.attributes
            ? _c("img", {
                staticClass: "w-16 h-16 object-cover rounded-md",
                attrs: {
                  src:
                    _vm.data.attributes.product.data.attributes.image !== null
                      ? _vm.$tools.getFileUrl(
                          _vm.data.attributes.product.data.attributes.image
                        )
                      : require("/assets/images/default.png"),
                },
                on: {
                  error: function ($event) {
                    _vm.data.attributes.product.data.attributes.image = require("/assets/images/default.png")
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "grid content-between space-y-2" }, [
            _vm.data &&
            _vm.data.attributes &&
            _vm.data.attributes.product &&
            _vm.data.attributes.product.data &&
            _vm.data.attributes.product.data.attributes
              ? _c(
                  "div",
                  { staticClass: "text-gray-700 text-sm font-semibold" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.data.attributes.product.data.attributes.name
                        ) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.data &&
            _vm.data.attributes &&
            _vm.data.attributes.district &&
            _vm.data.attributes.district.data &&
            _vm.data.attributes.district.data.attributes
              ? _c(
                  "div",
                  { staticClass: "text-green-700 font-medium text-xs" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.data.attributes.district.data.attributes.name
                        ) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.data && _vm.data.attributes
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex items-center text-gray-700 font-semibold text-sm space-x-3",
                  },
                  [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("min")) +
                          ": " +
                          _vm._s(_vm.data.attributes.min) +
                          " " +
                          _vm._s(_vm.$t("uz-kg"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("max")) +
                          ": " +
                          _vm._s(_vm.data.attributes.max) +
                          " " +
                          _vm._s(_vm.$t("uz-kg"))
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }