<template>
  <div class="relative">
    <div class="social bg-green-900" :class="socialOpen ? 'open' : ''">
      <div class="social__telegram">
        <div @click="socialOpen = !socialOpen" class="unvisible">
          <svg :class="socialOpen ? 'arrow-rotate' : ''" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9997 10.8284L7.04996 15.7782L5.63574 14.364L11.9997 8L18.3637 14.364L16.9495 15.7782L11.9997 10.8284Z" fill="white"/>
          </svg>
        </div>

        <a href="https://t.me/jasilawil" target="_blank">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.001 22C6.47813 22 2.00098 17.5228 2.00098 12C2.00098 6.47715 6.47813 2 12.001 2C17.5238 2 22.001 6.47715 22.001 12C22.001 17.5228 17.5238 22 12.001 22ZM8.89113 13.1708L8.90378 13.1628C9.48351 15.0767 9.77337 16.0337 9.77337 16.0337C9.88564 16.3442 10.04 16.3996 10.2273 16.3743C10.4145 16.3489 10.5139 16.2476 10.6361 16.1297C10.6361 16.1297 11.0324 15.7472 11.825 14.9823L14.3759 16.8698C14.8407 17.1266 15.1763 16.9941 15.2917 16.4377L16.9495 8.61641C17.1325 7.88842 16.8115 7.59644 16.247 7.82754L6.51397 11.5871C5.84996 11.854 5.85317 12.2255 6.39308 12.3911L8.89113 13.1708Z" fill="white"/>
          </svg>
        </a>
      </div>
      <div class="social__item">
<!--        <a href="">-->
<!--          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <mask id="mask0_20005263_4380" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">-->
<!--            <rect width="24" height="24" fill="#C4C4C4"/>-->
<!--            </mask>-->
<!--            <g mask="url(#mask0_20005263_4380)">-->
<!--            <path d="M12.3956 4.21227C11.1393 4.40827 10.1509 5.14412 9.62804 6.27197C9.25181 7.09035 9.17529 7.58894 9.17529 9.28416V10.4842H8.09442C7.495 10.4842 6.97529 10.498 6.93384 10.5152C6.8892 10.5358 6.82224 10.5839 6.78717 10.6252C6.72021 10.7008 6.72021 10.7249 6.72021 12.286C6.72021 13.7371 6.72659 13.8781 6.77761 13.9434C6.88601 14.0913 6.92746 14.0947 8.0721 14.0947H9.17529V17.8084V21.522L9.26775 21.6321L9.36021 21.7455L10.846 21.7559C12.1564 21.7627 12.3446 21.7593 12.4306 21.7111C12.6283 21.6011 12.6188 21.773 12.6188 17.7568V14.0947H13.8941C14.8124 14.0947 15.1886 14.0844 15.2492 14.0535C15.4246 13.9675 15.4246 13.9537 15.4246 12.2757V10.7249L15.3321 10.6149L15.2396 10.5014L13.926 10.4911L12.6156 10.4808L12.6251 9.37012L12.6347 8.2629L12.7304 8.06003C12.8356 7.83996 13.0109 7.6921 13.2469 7.62333C13.3138 7.6027 13.7857 7.58207 14.2927 7.57519C14.8028 7.56487 15.2524 7.55112 15.2906 7.5408C15.3321 7.53049 15.4022 7.48235 15.4437 7.43421L15.5202 7.34481V5.85934C15.5202 4.40139 15.5202 4.37388 15.4533 4.29823C15.4182 4.25697 15.3512 4.20883 15.3066 4.1882C15.1791 4.13662 12.7527 4.15725 12.3956 4.21227Z" fill="#E3E3E3"/>-->
<!--            </g>-->
<!--          </svg>-->
<!--        </a>-->
        <a href="https://www.linkedin.com/company/94823471" target='_blank'>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9109 21.2242V21.2235H20.9153V14.7687C20.9153 11.6109 20.2355 9.17847 16.5439 9.17847C14.7692 9.17847 13.5783 10.1523 13.0921 11.0756H13.0407V9.47327H9.54053V21.2235H13.1852V15.4052C13.1852 13.8733 13.4756 12.3919 15.3727 12.3919C17.242 12.3919 17.2699 14.1402 17.2699 15.5035V21.2242H20.9109Z" fill="#E3E3E3"/>
            <path d="M3.49121 9.47412H7.14028V21.2243H3.49121V9.47412Z" fill="#E3E3E3"/>
            <path d="M5.31366 3.33179C4.14693 3.33179 3.2002 4.27852 3.2002 5.44526C3.2002 6.61199 4.14693 7.57852 5.31366 7.57852C6.4804 7.57852 7.42713 6.61199 7.42713 5.44526C7.4264 4.27852 6.47966 3.33179 5.31366 3.33179V3.33179Z" fill="#E3E3E3"/>
          </svg>
        </a>
<!--        <a href="">-->
<!--          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <mask id="mask0_20005263_4382" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">-->
<!--            <rect x="0.160156" y="0.959961" width="24" height="24" fill="#C4C4C4"/>-->
<!--            </mask>-->
<!--            <g mask="url(#mask0_20005263_4382)">-->
<!--            <path d="M21.3063 12.5924V10.0063H19.9594V12.5924H17.2656V13.8854H19.9594V16.4714H21.3063V13.8854H24V12.5924H21.3063Z" fill="#E3E3E3"/>-->
<!--            <path d="M8.10293 12.4338V15.4738H12.403C11.7752 17.243 10.085 18.5138 8.10293 18.5138C5.58885 18.5138 3.54293 16.4678 3.54293 13.9538C3.54293 11.4397 5.58885 9.39376 8.10293 9.39376C9.19277 9.39376 10.2416 9.7844 11.0563 10.4942L13.0536 8.20208C11.6856 7.0104 9.92845 6.35376 8.10293 6.35376C3.91229 6.35376 0.50293 9.76312 0.50293 13.9538C0.50293 18.1444 3.91229 21.5538 8.10293 21.5538C12.2936 21.5538 15.7029 18.1444 15.7029 13.9538V12.4338H8.10293Z" fill="#E3E3E3"/>-->
<!--            </g>-->
<!--          </svg>-->
<!--        </a>-->
        <a href="https://twitter.com/GggiUzbekistan" target='_blank'>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_20005263_4383" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="2" y="3" width="20" height="21">
            <rect x="2.21191" y="3.81177" width="19.2" height="19.2" fill="#C4C4C4"/>
            </mask>
            <g mask="url(#mask0_20005263_4383)">
            <path d="M21.4119 7.45862C20.6979 7.77182 19.9371 7.97942 19.1439 8.08022C19.9599 7.59302 20.5827 6.82742 20.8755 5.90462C20.1147 6.35822 19.2747 6.67862 18.3795 6.85742C17.6571 6.08822 16.6275 5.61182 15.5043 5.61182C13.3251 5.61182 11.5707 7.38062 11.5707 9.54902C11.5707 9.86102 11.5971 10.161 11.6619 10.4466C8.38951 10.287 5.49391 8.71862 3.54871 6.32942C3.20911 6.91862 3.00991 7.59302 3.00991 8.31902C3.00991 9.68222 3.71191 10.8906 4.75831 11.5902C4.12591 11.5782 3.50551 11.3946 2.97991 11.1054C2.97991 11.1174 2.97991 11.133 2.97991 11.1486C2.97991 13.0614 4.34431 14.6502 6.13351 15.0162C5.81311 15.1038 5.46391 15.1458 5.10151 15.1458C4.84951 15.1458 4.59511 15.1314 4.35631 15.0786C4.86631 16.6374 6.31351 17.7834 8.03431 17.8206C6.69511 18.8682 4.99471 19.4994 3.15391 19.4994C2.83111 19.4994 2.52151 19.485 2.21191 19.4454C3.95551 20.5698 6.02191 21.2118 8.25031 21.2118C15.4935 21.2118 19.4535 15.2118 19.4535 10.011C19.4535 9.83702 19.4475 9.66902 19.4391 9.50222C20.2203 8.94782 20.8767 8.25542 21.4119 7.45862Z" fill="#E3E3E3"/>
            </g>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
  data() {
      return {
        socialOpen: false,
      }
    }
  }
</script>

<style scoped>
  .social {
    position: fixed;
    bottom: 8%;
    right: 2%;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    z-index: 10;
    padding: 40px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 100px;
    overflow: hidden;
    height: 56px;
    transition: .5s;
  }

  .social__telegram {
    position: absolute;
    top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .social svg {
    cursor: pointer;
  }

  .social__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    height: 100px;
    padding-top: 12px;
  }


  @media screen and (min-width: 1024px) {
    .social:hover {
      height: 130px;
    }
    .unvisible {
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    .social {
      height: 88px;
    }
    .social__item {
      padding-top: 45px;
    }

    .open {
      height: 170px;
    }
    .arrow-rotate {
      transform: rotate(180deg);
    }
  }
</style>
