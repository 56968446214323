import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a9e6a606 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3d30c1c0 = () => interopDefault(import('../pages/index/index.vue' /* webpackChunkName: "pages/index/index" */))
const _1d78e6fb = () => interopDefault(import('../pages/index/about.vue' /* webpackChunkName: "pages/index/about" */))
const _c601408a = () => interopDefault(import('../pages/index/advisory.vue' /* webpackChunkName: "pages/index/advisory" */))
const _68ea069e = () => interopDefault(import('../pages/index/agri-business.vue' /* webpackChunkName: "pages/index/agri-business" */))
const _9e9ac2be = () => interopDefault(import('../pages/index/agri-business/index.vue' /* webpackChunkName: "pages/index/agri-business/index" */))
const _1c5df5ef = () => interopDefault(import('../pages/index/agri-finance/index.vue' /* webpackChunkName: "pages/index/agri-finance/index" */))
const _1497969a = () => interopDefault(import('../pages/index/agri-market.vue' /* webpackChunkName: "pages/index/agri-market" */))
const _48c12594 = () => interopDefault(import('../pages/index/agro-tools/index.vue' /* webpackChunkName: "pages/index/agro-tools/index" */))
const _4565e04c = () => interopDefault(import('../pages/index/chats/index.vue' /* webpackChunkName: "pages/index/chats/index" */))
const _65f12c3a = () => interopDefault(import('../pages/index/chats-old.vue' /* webpackChunkName: "pages/index/chats-old" */))
const _c3afbb3e = () => interopDefault(import('../pages/index/consultant-enterprise.vue' /* webpackChunkName: "pages/index/consultant-enterprise" */))
const _6f843599 = () => interopDefault(import('../pages/index/consultant-individual.vue' /* webpackChunkName: "pages/index/consultant-individual" */))
const _44e2b872 = () => interopDefault(import('../pages/index/content-provider.vue' /* webpackChunkName: "pages/index/content-provider" */))
const _4db843ea = () => interopDefault(import('../pages/index/e-learning/index.vue' /* webpackChunkName: "pages/index/e-learning/index" */))
const _275dbc3c = () => interopDefault(import('../pages/index/graph.vue' /* webpackChunkName: "pages/index/graph" */))
const _06d864d7 = () => interopDefault(import('../pages/index/login.vue' /* webpackChunkName: "pages/index/login" */))
const _35c59dfc = () => interopDefault(import('../pages/index/marketplace/index.vue' /* webpackChunkName: "pages/index/marketplace/index" */))
const _713a090e = () => interopDefault(import('../pages/index/new.vue' /* webpackChunkName: "pages/index/new" */))
const _8c9a8c56 = () => interopDefault(import('../pages/index/news.vue' /* webpackChunkName: "pages/index/news" */))
const _b74066d0 = () => interopDefault(import('../pages/index/news/index.vue' /* webpackChunkName: "pages/index/news/index" */))
const _04cd3294 = () => interopDefault(import('../pages/index/news/_detail.vue' /* webpackChunkName: "pages/index/news/_detail" */))
const _564ff0a5 = () => interopDefault(import('../pages/index/register.vue' /* webpackChunkName: "pages/index/register" */))
const _6fb31a12 = () => interopDefault(import('../pages/index/register-type.vue' /* webpackChunkName: "pages/index/register-type" */))
const _2b4148db = () => interopDefault(import('../pages/index/reset-password.vue' /* webpackChunkName: "pages/index/reset-password" */))
const _179ae8de = () => interopDefault(import('../pages/index/user-enterprise.vue' /* webpackChunkName: "pages/index/user-enterprise" */))
const _74e2c26e = () => interopDefault(import('../pages/index/user-individual.vue' /* webpackChunkName: "pages/index/user-individual" */))
const _83bb9760 = () => interopDefault(import('../pages/index/e-learning/detail_old.vue' /* webpackChunkName: "pages/index/e-learning/detail_old" */))
const _6eb2964d = () => interopDefault(import('../pages/index/agri-finance/_detail.vue' /* webpackChunkName: "pages/index/agri-finance/_detail" */))
const _119ee769 = () => interopDefault(import('../pages/index/e-learning/_detail.vue' /* webpackChunkName: "pages/index/e-learning/_detail" */))
const _665f21cc = () => interopDefault(import('../pages/index/marketplace/_detail.vue' /* webpackChunkName: "pages/index/marketplace/_detail" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _a9e6a606,
    children: [{
      path: "",
      component: _3d30c1c0,
      name: "index___en"
    }, {
      path: "about",
      component: _1d78e6fb,
      name: "index-about___en"
    }, {
      path: "advisory",
      component: _c601408a,
      name: "index-advisory___en"
    }, {
      path: "agri-business",
      component: _68ea069e,
      children: [{
        path: "",
        component: _9e9ac2be,
        name: "index-agri-business___en"
      }]
    }, {
      path: "agri-finance",
      component: _1c5df5ef,
      name: "index-agri-finance___en"
    }, {
      path: "agri-market",
      component: _1497969a,
      name: "index-agri-market___en"
    }, {
      path: "agro-tools",
      component: _48c12594,
      name: "index-agro-tools___en"
    }, {
      path: "chats",
      component: _4565e04c,
      name: "index-chats___en"
    }, {
      path: "chats-old",
      component: _65f12c3a,
      name: "index-chats-old___en"
    }, {
      path: "consultant-enterprise",
      component: _c3afbb3e,
      name: "index-consultant-enterprise___en"
    }, {
      path: "consultant-individual",
      component: _6f843599,
      name: "index-consultant-individual___en"
    }, {
      path: "content-provider",
      component: _44e2b872,
      name: "index-content-provider___en"
    }, {
      path: "e-learning",
      component: _4db843ea,
      name: "index-e-learning___en"
    }, {
      path: "graph",
      component: _275dbc3c,
      name: "index-graph___en"
    }, {
      path: "login",
      component: _06d864d7,
      name: "index-login___en"
    }, {
      path: "marketplace",
      component: _35c59dfc,
      name: "index-marketplace___en"
    }, {
      path: "new",
      component: _713a090e,
      name: "index-new___en"
    }, {
      path: "news",
      component: _8c9a8c56,
      children: [{
        path: "",
        component: _b74066d0,
        name: "index-news___en"
      }, {
        path: ":detail",
        component: _04cd3294,
        name: "index-news-detail___en"
      }]
    }, {
      path: "register",
      component: _564ff0a5,
      name: "index-register___en"
    }, {
      path: "register-type",
      component: _6fb31a12,
      name: "index-register-type___en"
    }, {
      path: "reset-password",
      component: _2b4148db,
      name: "index-reset-password___en"
    }, {
      path: "user-enterprise",
      component: _179ae8de,
      name: "index-user-enterprise___en"
    }, {
      path: "user-individual",
      component: _74e2c26e,
      name: "index-user-individual___en"
    }, {
      path: "e-learning/detail_old",
      component: _83bb9760,
      name: "index-e-learning-detail_old___en"
    }, {
      path: "agri-finance/:detail?",
      component: _6eb2964d,
      name: "index-agri-finance-detail___en"
    }, {
      path: "e-learning/:detail?",
      component: _119ee769,
      name: "index-e-learning-detail___en"
    }, {
      path: "marketplace/:detail",
      component: _665f21cc,
      name: "index-marketplace-detail___en"
    }]
  }, {
    path: "/kaa",
    component: _a9e6a606,
    children: [{
      path: "",
      component: _3d30c1c0,
      name: "index___kaa"
    }, {
      path: "about",
      component: _1d78e6fb,
      name: "index-about___kaa"
    }, {
      path: "advisory",
      component: _c601408a,
      name: "index-advisory___kaa"
    }, {
      path: "agri-business",
      component: _68ea069e,
      children: [{
        path: "",
        component: _9e9ac2be,
        name: "index-agri-business___kaa"
      }]
    }, {
      path: "agri-finance",
      component: _1c5df5ef,
      name: "index-agri-finance___kaa"
    }, {
      path: "agri-market",
      component: _1497969a,
      name: "index-agri-market___kaa"
    }, {
      path: "agro-tools",
      component: _48c12594,
      name: "index-agro-tools___kaa"
    }, {
      path: "chats",
      component: _4565e04c,
      name: "index-chats___kaa"
    }, {
      path: "chats-old",
      component: _65f12c3a,
      name: "index-chats-old___kaa"
    }, {
      path: "consultant-enterprise",
      component: _c3afbb3e,
      name: "index-consultant-enterprise___kaa"
    }, {
      path: "consultant-individual",
      component: _6f843599,
      name: "index-consultant-individual___kaa"
    }, {
      path: "content-provider",
      component: _44e2b872,
      name: "index-content-provider___kaa"
    }, {
      path: "e-learning",
      component: _4db843ea,
      name: "index-e-learning___kaa"
    }, {
      path: "graph",
      component: _275dbc3c,
      name: "index-graph___kaa"
    }, {
      path: "login",
      component: _06d864d7,
      name: "index-login___kaa"
    }, {
      path: "marketplace",
      component: _35c59dfc,
      name: "index-marketplace___kaa"
    }, {
      path: "new",
      component: _713a090e,
      name: "index-new___kaa"
    }, {
      path: "news",
      component: _8c9a8c56,
      children: [{
        path: "",
        component: _b74066d0,
        name: "index-news___kaa"
      }, {
        path: ":detail",
        component: _04cd3294,
        name: "index-news-detail___kaa"
      }]
    }, {
      path: "register",
      component: _564ff0a5,
      name: "index-register___kaa"
    }, {
      path: "register-type",
      component: _6fb31a12,
      name: "index-register-type___kaa"
    }, {
      path: "reset-password",
      component: _2b4148db,
      name: "index-reset-password___kaa"
    }, {
      path: "user-enterprise",
      component: _179ae8de,
      name: "index-user-enterprise___kaa"
    }, {
      path: "user-individual",
      component: _74e2c26e,
      name: "index-user-individual___kaa"
    }, {
      path: "e-learning/detail_old",
      component: _83bb9760,
      name: "index-e-learning-detail_old___kaa"
    }, {
      path: "agri-finance/:detail?",
      component: _6eb2964d,
      name: "index-agri-finance-detail___kaa"
    }, {
      path: "e-learning/:detail?",
      component: _119ee769,
      name: "index-e-learning-detail___kaa"
    }, {
      path: "marketplace/:detail",
      component: _665f21cc,
      name: "index-marketplace-detail___kaa"
    }]
  }, {
    path: "/ru",
    component: _a9e6a606,
    children: [{
      path: "",
      component: _3d30c1c0,
      name: "index___ru"
    }, {
      path: "about",
      component: _1d78e6fb,
      name: "index-about___ru"
    }, {
      path: "advisory",
      component: _c601408a,
      name: "index-advisory___ru"
    }, {
      path: "agri-business",
      component: _68ea069e,
      children: [{
        path: "",
        component: _9e9ac2be,
        name: "index-agri-business___ru"
      }]
    }, {
      path: "agri-finance",
      component: _1c5df5ef,
      name: "index-agri-finance___ru"
    }, {
      path: "agri-market",
      component: _1497969a,
      name: "index-agri-market___ru"
    }, {
      path: "agro-tools",
      component: _48c12594,
      name: "index-agro-tools___ru"
    }, {
      path: "chats",
      component: _4565e04c,
      name: "index-chats___ru"
    }, {
      path: "chats-old",
      component: _65f12c3a,
      name: "index-chats-old___ru"
    }, {
      path: "consultant-enterprise",
      component: _c3afbb3e,
      name: "index-consultant-enterprise___ru"
    }, {
      path: "consultant-individual",
      component: _6f843599,
      name: "index-consultant-individual___ru"
    }, {
      path: "content-provider",
      component: _44e2b872,
      name: "index-content-provider___ru"
    }, {
      path: "e-learning",
      component: _4db843ea,
      name: "index-e-learning___ru"
    }, {
      path: "graph",
      component: _275dbc3c,
      name: "index-graph___ru"
    }, {
      path: "login",
      component: _06d864d7,
      name: "index-login___ru"
    }, {
      path: "marketplace",
      component: _35c59dfc,
      name: "index-marketplace___ru"
    }, {
      path: "new",
      component: _713a090e,
      name: "index-new___ru"
    }, {
      path: "news",
      component: _8c9a8c56,
      children: [{
        path: "",
        component: _b74066d0,
        name: "index-news___ru"
      }, {
        path: ":detail",
        component: _04cd3294,
        name: "index-news-detail___ru"
      }]
    }, {
      path: "register",
      component: _564ff0a5,
      name: "index-register___ru"
    }, {
      path: "register-type",
      component: _6fb31a12,
      name: "index-register-type___ru"
    }, {
      path: "reset-password",
      component: _2b4148db,
      name: "index-reset-password___ru"
    }, {
      path: "user-enterprise",
      component: _179ae8de,
      name: "index-user-enterprise___ru"
    }, {
      path: "user-individual",
      component: _74e2c26e,
      name: "index-user-individual___ru"
    }, {
      path: "e-learning/detail_old",
      component: _83bb9760,
      name: "index-e-learning-detail_old___ru"
    }, {
      path: "agri-finance/:detail?",
      component: _6eb2964d,
      name: "index-agri-finance-detail___ru"
    }, {
      path: "e-learning/:detail?",
      component: _119ee769,
      name: "index-e-learning-detail___ru"
    }, {
      path: "marketplace/:detail",
      component: _665f21cc,
      name: "index-marketplace-detail___ru"
    }]
  }, {
    path: "/uz",
    component: _a9e6a606,
    children: [{
      path: "",
      component: _3d30c1c0,
      name: "index___uz"
    }, {
      path: "about",
      component: _1d78e6fb,
      name: "index-about___uz"
    }, {
      path: "advisory",
      component: _c601408a,
      name: "index-advisory___uz"
    }, {
      path: "agri-business",
      component: _68ea069e,
      children: [{
        path: "",
        component: _9e9ac2be,
        name: "index-agri-business___uz"
      }]
    }, {
      path: "agri-finance",
      component: _1c5df5ef,
      name: "index-agri-finance___uz"
    }, {
      path: "agri-market",
      component: _1497969a,
      name: "index-agri-market___uz"
    }, {
      path: "agro-tools",
      component: _48c12594,
      name: "index-agro-tools___uz"
    }, {
      path: "chats",
      component: _4565e04c,
      name: "index-chats___uz"
    }, {
      path: "chats-old",
      component: _65f12c3a,
      name: "index-chats-old___uz"
    }, {
      path: "consultant-enterprise",
      component: _c3afbb3e,
      name: "index-consultant-enterprise___uz"
    }, {
      path: "consultant-individual",
      component: _6f843599,
      name: "index-consultant-individual___uz"
    }, {
      path: "content-provider",
      component: _44e2b872,
      name: "index-content-provider___uz"
    }, {
      path: "e-learning",
      component: _4db843ea,
      name: "index-e-learning___uz"
    }, {
      path: "graph",
      component: _275dbc3c,
      name: "index-graph___uz"
    }, {
      path: "login",
      component: _06d864d7,
      name: "index-login___uz"
    }, {
      path: "marketplace",
      component: _35c59dfc,
      name: "index-marketplace___uz"
    }, {
      path: "new",
      component: _713a090e,
      name: "index-new___uz"
    }, {
      path: "news",
      component: _8c9a8c56,
      children: [{
        path: "",
        component: _b74066d0,
        name: "index-news___uz"
      }, {
        path: ":detail",
        component: _04cd3294,
        name: "index-news-detail___uz"
      }]
    }, {
      path: "register",
      component: _564ff0a5,
      name: "index-register___uz"
    }, {
      path: "register-type",
      component: _6fb31a12,
      name: "index-register-type___uz"
    }, {
      path: "reset-password",
      component: _2b4148db,
      name: "index-reset-password___uz"
    }, {
      path: "user-enterprise",
      component: _179ae8de,
      name: "index-user-enterprise___uz"
    }, {
      path: "user-individual",
      component: _74e2c26e,
      name: "index-user-individual___uz"
    }, {
      path: "e-learning/detail_old",
      component: _83bb9760,
      name: "index-e-learning-detail_old___uz"
    }, {
      path: "agri-finance/:detail?",
      component: _6eb2964d,
      name: "index-agri-finance-detail___uz"
    }, {
      path: "e-learning/:detail?",
      component: _119ee769,
      name: "index-e-learning-detail___uz"
    }, {
      path: "marketplace/:detail",
      component: _665f21cc,
      name: "index-marketplace-detail___uz"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
