var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "py-8 px-3 relative sm:px-8" }, [
      _c(
        "div",
        {
          staticClass:
            "absolute right-4 top-4 text-center p-1 rounded-md hover:bg-gray-100 cursor-pointer",
          on: {
            click: function ($event) {
              return _vm.onClose("canceled")
            },
          },
        },
        [_c("i", { staticClass: "bx bx-x text-2xl text-gray-700" })]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex justify-between" }, [
        _c("div", { staticClass: "flex justify-between items-center mb-3" }, [
          _c("div", { attrs: { id: "signin" } }, [
            _c(
              "button",
              {
                staticClass:
                  "text-xl font-medium text-gray-500 focus:outline-none",
                class: [
                  _vm.status === "sign-in"
                    ? "border-b border-green-600 text-green-600"
                    : "hover:text-green-600 text-gray-600",
                ],
                on: {
                  click: function ($event) {
                    return _vm.openSignIn()
                  },
                },
              },
              [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("login")) +
                    "\n            "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { id: "register" } }, [
            _c(
              "button",
              {
                staticClass:
                  "text-xl font-medium text-gray-500 ml-6 focus:outline-none",
                class: [
                  _vm.status === "register"
                    ? "border-b border-green-600 text-green-600"
                    : "hover:text-green-600  text-gray-600",
                ],
              },
              [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("registration")) +
                    "\n            "
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-center my-4 text-gray-600 font-semibold" },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("how-will-you-use-the-platform")) +
              "\n      "
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "grid grid-cols-2 gap-4" }, [
        _c(
          "div",
          {
            staticClass:
              "border rounded-md cursor-pointer border-gray-200 p-4 hover:shadow-md",
            on: {
              click: function ($event) {
                return _vm.toEnterprise()
              },
            },
          },
          [
            _c("img", {
              attrs: { src: require("/assets/images/enterprise.png"), alt: "" },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex justify-center text-gray-600 text-sm font-semibold",
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("as-an-enterprise")) +
                    "\n          "
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "border rounded-md cursor-pointer border-gray-200 p-4 hover:shadow-md",
            on: {
              click: function ($event) {
                return _vm.toIndividual()
              },
            },
          },
          [
            _c("img", {
              attrs: { src: require("/assets/images/individual.png"), alt: "" },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex justify-center text-gray-600 text-sm font-semibold",
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("as-an-individual")) +
                    "\n          "
                ),
              ]
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }