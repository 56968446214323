var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "lg:flex block items-center justify-between" }, [
      _c("div", { staticClass: "font-semibold text-gray-700 text-2xl" }, [
        _c("span", { staticClass: "text-green-800" }, [
          _vm._v(_vm._s(_vm.name)),
        ]),
      ]),
      _vm._v(" "),
      !_vm.isConsultant
        ? _c("div", { staticClass: "mt-1 flex rounded-md shadow-sm" }, [
            _c(
              "div",
              {
                staticClass:
                  "relative py-0.5 bg-green-700 rounded-md rounded-r-none flex items-stretch flex-grow focus-within:z-10 lg:my-0 mt-4",
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter.text,
                      expression: "filter.text",
                    },
                  ],
                  staticClass:
                    "block w-full p-2 ml-0.5 focus:outline-none sm:text-sm border-gray-300 border rounded-md",
                  attrs: {
                    id: "search",
                    type: "search",
                    name: "search",
                    placeholder: "Search",
                  },
                  domProps: { value: _vm.filter.text },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.filter, "text", $event.target.value)
                    },
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _vm._m(0),
          ])
        : _c(
            "div",
            {
              staticClass:
                "border lg:block rounded-md border-green-700 text-green-700 p-2 hidden",
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter.category,
                      expression: "filter.category",
                    },
                  ],
                  staticClass:
                    "font-semibold focus:outline-none flex justify-between w-32",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.filter,
                          "category",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.onChangeCategory,
                    ],
                  },
                },
                [
                  _vm._l(_vm.categories, function (category, index) {
                    return _c(
                      "option",
                      {
                        key: index,
                        staticClass: "pr-4",
                        domProps: { value: category.id },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(category.attributes.name) +
                            "\n          "
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("i", { staticClass: "bx bx-chevron-down text-lg" }),
                ],
                2
              ),
            ]
          ),
    ]),
    _vm._v(" "),
    !_vm.isConsultant
      ? _c(
          "div",
          {
            staticClass:
              "lg:flex grid-cols-3 items-center hidden justify-center lg:gap-7 gap-4 my-6",
          },
          _vm._l(_vm.categories, function (category, index) {
            return _c(
              "button",
              {
                key: index,
                staticClass:
                  "rounded-md flex items-center focus:outline-none py-1.5 px-4 duration-500",
                class:
                  _vm.$route.query.category &&
                  parseInt(_vm.$route.query.category) === category.id
                    ? "bg-green-700 text-white"
                    : "border border-green-700 text-green-700 hover:text-white hover:bg-green-700",
                on: {
                  click: function ($event) {
                    return _vm.toChange(category)
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      category.attributes ? category.attributes.name : ""
                    ) +
                    "\n      "
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "lg:hidden flex mt-4" },
      [
        _c(
          "swiper",
          {
            ref: "swiper",
            staticClass: "swiper",
            attrs: {
              options: _vm.categoriesOption,
              autoplay: { delay: 2000, disableOnIteraction: false },
              pagination: { clickable: true },
            },
          },
          [
            _c(
              "client-only",
              _vm._l(_vm.categories, function (category, index) {
                return _c("swiper-slide", { key: index }, [
                  _c(
                    "button",
                    {
                      staticClass: "rounded-md py-1.5 mb-4 w-full duration-500",
                      class:
                        _vm.$route.query.category &&
                        parseInt(_vm.$route.query.category) === category.id
                          ? "bg-green-700 text-white"
                          : "border border-green-700 text-green-700 hover:text-white hover:bg-green-700",
                      on: {
                        click: function ($event) {
                          return _vm.toChange(category)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            category.attributes ? category.attributes.name : ""
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ])
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "-ml-px relative inline-flex items-center space-x-2 px-4 py-2 lg:my-0 mt-4 bg-green-700 text-sm font-medium rounded-r-md focus:outline-none",
        attrs: { type: "button" },
      },
      [_c("i", { staticClass: "bx bx-search text-lg text-white" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }