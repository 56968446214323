var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass:
        "border-t border-gray-200 px-4 flex items-center justify-between sm:px-0",
    },
    [
      _c("div", { staticClass: "-mt-px w-0 flex-1 flex" }, [
        _c(
          "button",
          {
            staticClass:
              "pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 focus:outline-none",
            class: {
              "border-t-2 border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300":
                Number(_vm.value) !== 1,
            },
            attrs: { disabled: Number(_vm.value) === 1 },
            on: {
              click: function ($event) {
                return _vm.$emit("input", +_vm.value - 1)
              },
            },
          },
          [
            _c(
              "svg",
              {
                staticClass: "mr-3 h-5 w-5 text-gray-400",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 20 20",
                  fill: "currentColor",
                  "aria-hidden": "true",
                },
              },
              [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    d: "M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z",
                    "clip-rule": "evenodd",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "hidden md:inline-block" }, [
              _vm._v("Назад"),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "md:-mt-px md:flex" },
        [
          _vm.section > 1
            ? [
                _c(
                  "button",
                  {
                    staticClass:
                      "border-t-2 pt-4 px-3 md:px-4 inline-flex items-center text-sm font-medium",
                    class:
                      Number(_vm.value) === 1
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("input", 1)
                      },
                    },
                  },
                  [_vm._v("\n          1\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "border-transparent text-gray-500 border-t-2 pt-4 px-3 md:px-4 inline-flex items-center text-sm font-medium focus:outline-none",
                    on: { click: _vm.goBackwardSection },
                  },
                  [_vm._v("\n          ...\n        ")]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.pages, function (page) {
            return _c(
              "button",
              {
                key: page,
                staticClass:
                  "border-t-2 pt-4 px-3 md:px-4 inline-flex items-center text-sm font-medium focus:outline-none",
                class:
                  page === Number(_vm.value)
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                attrs: { "aria-current": "page" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("input", page)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(page) + "\n      ")]
            )
          }),
          _vm._v(" "),
          _vm.section < _vm.sections
            ? [
                _c(
                  "button",
                  {
                    staticClass:
                      "border-transparent text-gray-500 border-t-2 pt-4 px-3 md:px-4 inline-flex items-center text-sm font-medium focus:outline-none",
                    on: { click: _vm.goForwardSection },
                  },
                  [_vm._v("\n          ...\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "border-t-2 pt-4 px-3 md:px-4 inline-flex items-center text-sm font-medium",
                    class:
                      Number(_vm.value) === _vm.length
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("input", _vm.length)
                      },
                    },
                  },
                  [_vm._v("\n          " + _vm._s(_vm.length) + "\n        ")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "-mt-px w-0 flex-1 flex justify-end" }, [
        _c(
          "button",
          {
            staticClass:
              "pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 focus:outline-none",
            class: {
              "border-t-2 border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300":
                Number(_vm.value) !== _vm.length,
            },
            attrs: { disabled: Number(_vm.value) === _vm.length },
            on: {
              click: function ($event) {
                return _vm.$emit("input", +_vm.value + 1)
              },
            },
          },
          [
            _c("span", { staticClass: "hidden md:inline-block" }, [
              _vm._v("След"),
            ]),
            _vm._v(" "),
            _c(
              "svg",
              {
                staticClass: "ml-3 h-5 w-5 text-gray-400",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 20 20",
                  fill: "currentColor",
                  "aria-hidden": "true",
                },
              },
              [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    d: "M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z",
                    "clip-rule": "evenodd",
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }