var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("trigger", null, { onClick: _vm.toggle }),
      _vm._v(" "),
      _c(
        "section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen,
              expression: "isOpen",
            },
          ],
          staticClass: "fixed z-20 inset-0 overflow-hidden",
          attrs: {
            "aria-labelledby": "slide-over-title",
            role: "dialog",
            "aria-modal": "true",
          },
        },
        [
          _c(
            "div",
            { staticClass: "absolute inset-0 overflow-hidden" },
            [
              _c(
                "transition",
                {
                  attrs: {
                    "enter-active-class": "ease-in-out duration-200",
                    "enter-class": "opacity-0",
                    "enter-to-class": "opacity-100",
                    "leave-active-class": "ease-in-out duration-300",
                    "leave-class": "opacity-100",
                    "leave-to-class": "opacity-0",
                  },
                  on: {
                    "after-enter": function ($event) {
                      _vm.showContent = true
                    },
                  },
                },
                [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isOpen,
                        expression: "isOpen",
                      },
                    ],
                    staticClass:
                      "absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity",
                    attrs: { "aria-hidden": "true" },
                    on: { click: _vm.hideContent },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "absolute inset-y-0 right-0 pl-10 max-w-full flex",
                },
                [
                  _c(
                    "transition",
                    {
                      attrs: {
                        "enter-active-class":
                          "transform transition ease-in-out duration-200 sm:duration-400",
                        "enter-class": "translate-x-full",
                        "enter-to-class": "translate-x-0",
                        "leave-active-class":
                          "transform transition ease-in-out duration-300 sm:duration-400",
                        "leave-class": "translate-x-0",
                        "leave-to-class": "translate-x-full",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showContent,
                              expression: "showContent",
                            },
                          ],
                          staticClass: "w-screen max-w-xl",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll",
                            },
                            [
                              _c("div", { staticClass: "px-4 sm:px-6" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-start justify-between",
                                  },
                                  [
                                    _vm._t("title"),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ml-3 h-7 flex items-center",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.hideContent.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "sr-only" },
                                              [_vm._v("Close panel")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "svg",
                                              {
                                                staticClass: "h-6 w-6",
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  fill: "none",
                                                  viewBox: "0 0 24 24",
                                                  stroke: "currentColor",
                                                  "aria-hidden": "true",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "stroke-width": "2",
                                                    d: "M6 18L18 6M6 6l12 12",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mt-6 relative flex-1 px-4 sm:px-6",
                                },
                                [_vm._t("default")],
                                2
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }