<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->

    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
        <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
        <div
          class="
            relative
            bg-white
            rounded-lg
            px-4
            pt-5
            pb-4
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:max-w-sm sm:w-full sm:p-6
          "
        >
          <div>
            <div @click='onClose()' style='display: flex; justify-content: flex-end; cursor: pointer'><img width='35' height='35' src='~/assets/images/close.png' alt=''></div>

            <div
              class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                class="h-6 w-6 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                {{ $t('information-submitted') }}
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  {{ $t('consultant-warning-info') }}
                </p>
              </div>
            </div>
          </div>
<!--          <div class="mt-5 sm:mt-6">-->
<!--            <button-->
<!--              type="button"-->
<!--              class="-->
<!--                inline-flex-->
<!--                justify-center-->
<!--                w-full-->
<!--                rounded-md-->
<!--                border border-transparent-->
<!--                shadow-sm-->
<!--                px-4-->
<!--                py-2-->
<!--                bg-green-600-->
<!--                text-base-->
<!--                font-medium-->
<!--                text-white-->
<!--                hover:bg-green-700-->
<!--                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500-->
<!--                sm:text-sm-->
<!--              "-->
<!--              @click="onClose()"-->
<!--            >-->
<!--              {{ $t('close') }}-->
<!--            </button>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ConsultantWarning',
  props: {
    title: {
      type: String,
      default: 'Consultant Warning',
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
  },
}
</script>
