var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-4" }, [
    _c(
      "div",
      { staticClass: "hidden sm:block absolute top-0 right-0 pt-4 pr-4" },
      [
        _c(
          "button",
          {
            staticClass:
              "bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.onClose("canceled")
              },
            },
          },
          [
            _c("span", { staticClass: "sr-only" }, [
              _vm._v(_vm._s(_vm.$t("close"))),
            ]),
            _vm._v(" "),
            _c(
              "svg",
              {
                attrs: {
                  width: "24px",
                  height: "24px",
                  viewBox: "0 0 24 24",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("g", { attrs: { "data-name": "Layer 2" } }, [
                  _c("g", { attrs: { "data-name": "close" } }, [
                    _c("rect", {
                      attrs: {
                        width: "24",
                        height: "24",
                        transform: "rotate(180 12 12)",
                        opacity: "0",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z",
                      },
                    }),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "sm:flex sm:items-start" }, [
      _c(
        "div",
        {
          staticClass:
            "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10",
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "24px",
                height: "24px",
                viewBox: "0 0 24 24",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("g", [
                _c("path", { attrs: { fill: "none", d: "M0 0h24v24H0z" } }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    "fill-rule": "nonzero",
                    d: "M12.866 3l9.526 16.5a1 1 0 0 1-.866 1.5H2.474a1 1 0 0 1-.866-1.5L11.134 3a1 1 0 0 1 1.732 0zm-8.66 16h15.588L12 5.5 4.206 19zM11 16h2v2h-2v-2zm0-7h2v5h-2V9z",
                  },
                }),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" },
        [
          _c(
            "h3",
            {
              staticClass: "text-lg leading-6 font-medium text-gray-900",
              attrs: { id: "modal-title" },
            },
            [
              _vm._v(
                "\n          " + _vm._s(_vm.$t("confirmation")) + "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mt-2" }, [
            _c("p", { staticClass: "text-sm text-gray-500" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("finish-chat")) +
                  "\n          "
              ),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-1" },
      [
        _c(
          "button",
          {
            staticClass:
              "mt-3 text-xs text-green-500 bg-green-100 font-medium flex items-center rounded-md p-2",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.toFinish("canceled")
              },
            },
          },
          [_vm._v("\n        " + _vm._s(_vm.$t("finish")) + "\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "mt-3 text-xs text-gray-500 bg-gray-200 font-medium flex items-center rounded-md p-2",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.onClose("canceled")
              },
            },
          },
          [_vm._v("\n        " + _vm._s(_vm.$t("cancel")) + "\n      ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }