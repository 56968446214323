var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "space-y-3" },
    [
      _c("ValidationObserver", {
        attrs: { slim: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ handleSubmit, invalid, valid }) {
              return [
                _c(
                  "form",
                  {
                    attrs: { novalidate: "" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.onSubmit)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "grid md:grid-cols-2 grid-cols-1 md:px-6 px-4 pb-4 md:pb-6 gap-4",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "mt-1" },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "block mb-1 text-sm font-medium text-gray-700",
                                attrs: { for: "firstname" },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("firstname")) +
                                    "*"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "firstname",
                                rules: "required",
                                mode: "eager",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.account.name,
                                              expression: "account.name",
                                            },
                                          ],
                                          staticClass:
                                            "focus:outline-none appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 sm:text-sm",
                                          class:
                                            errors.length > 0
                                              ? "border-red-400"
                                              : _vm.account.name
                                              ? "border-green-600"
                                              : "border-gray-300",
                                          attrs: {
                                            name: "firstname",
                                            autocomplete: "text",
                                            placeholder: _vm.$t("firstname"),
                                            disabled: _vm.isRegisterSuccess,
                                            required: "",
                                          },
                                          domProps: { value: _vm.account.name },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.account,
                                                "name",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt-1" },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "block mb-1 text-sm font-medium text-gray-700",
                                attrs: { for: "lastname" },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("lastname")) +
                                    "*"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "lastname",
                                rules: "required",
                                mode: "eager",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.account.surname,
                                              expression: "account.surname",
                                            },
                                          ],
                                          staticClass:
                                            "focus:outline-none appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 sm:text-sm",
                                          class:
                                            errors.length > 0
                                              ? "border-red-400"
                                              : _vm.account.surname
                                              ? "border-green-600"
                                              : "border-gray-300",
                                          attrs: {
                                            name: "lastname",
                                            autocomplete: "text",
                                            placeholder: _vm.$t("lastname"),
                                            disabled: _vm.isRegisterSuccess,
                                            required: "",
                                          },
                                          domProps: {
                                            value: _vm.account.surname,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.account,
                                                "surname",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt-1" },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "block text-sm mb-1 font-medium text-gray-700",
                                attrs: { for: "gender" },
                              },
                              [_vm._v(_vm._s(_vm.$t("gender")) + "*")]
                            ),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "gender",
                                rules: "required",
                                mode: "eager",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.account.gender,
                                                expression: "account.gender",
                                              },
                                            ],
                                            staticClass:
                                              "focus:outline-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 sm:text-sm",
                                            class:
                                              errors.length > 0
                                                ? "border-red-400"
                                                : _vm.account.gender
                                                ? "border-green-600"
                                                : "border-gray-300",
                                            attrs: {
                                              name: "option",
                                              disabled: _vm.isRegisterSuccess,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.account,
                                                  "gender",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          _vm._l(
                                            _vm.genders,
                                            function (gender, index) {
                                              return _c(
                                                "option",
                                                {
                                                  key: index,
                                                  domProps: {
                                                    value: gender.id,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(gender.name) +
                                                      "\n                "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.$route.query.type === "consultant"
                          ? _c(
                              "div",
                              { staticClass: "mt-1" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "block text-sm mb-1 font-medium text-gray-700",
                                    attrs: { for: "region" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("region")) + "*")]
                                ),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "region",
                                    rules: "required",
                                    mode: "eager",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.account.region,
                                                    expression:
                                                      "account.region",
                                                  },
                                                ],
                                                staticClass:
                                                  "focus:outline-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 sm:text-sm",
                                                class:
                                                  errors.length > 0
                                                    ? "border-red-400"
                                                    : _vm.region
                                                    ? "border-green-600"
                                                    : "border-gray-300",
                                                attrs: {
                                                  name: "option",
                                                  disabled:
                                                    _vm.isRegisterSuccess,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      _vm.account,
                                                      "region",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                },
                                              },
                                              _vm._l(
                                                _vm.dataRegions,
                                                function (region, index) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: index,
                                                      domProps: {
                                                        value: region.id,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            region.attributes
                                                              .name
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt-1" },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "block text-sm mb-1 font-medium text-gray-700",
                                attrs: { for: "district" },
                              },
                              [_vm._v(_vm._s(_vm.$t("district")) + "*")]
                            ),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "district",
                                rules: "required",
                                mode: "eager",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.account.district,
                                                expression: "account.district",
                                              },
                                            ],
                                            staticClass:
                                              "focus:outline-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 sm:text-sm",
                                            class:
                                              errors.length > 0
                                                ? "border-red-400"
                                                : _vm.account.disctict
                                                ? "border-green-600"
                                                : "border-gray-300",
                                            attrs: {
                                              disabled:
                                                _vm.districts.length === 0 ||
                                                _vm.isRegisterSuccess,
                                              name: "option",
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.account,
                                                  "district",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          _vm._l(
                                            _vm.districts,
                                            function (district, index) {
                                              return _c(
                                                "option",
                                                {
                                                  key: index,
                                                  domProps: {
                                                    value: district.id,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        district.attributes.name
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt-1" },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "block mb-1 text-sm font-medium text-gray-700",
                                attrs: { for: "phone" },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("phone")) +
                                    "*"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "phone",
                                rules: "required|phone",
                                mode: "eager",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.account.phone,
                                              expression: "account.phone",
                                            },
                                          ],
                                          staticClass:
                                            "focus:outline-none appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 sm:text-sm",
                                          class:
                                            errors.length > 0
                                              ? "border-red-400"
                                              : _vm.phoneOrEmail
                                              ? "border-green-600"
                                              : "border-gray-300",
                                          attrs: {
                                            name: "phone",
                                            type: "text",
                                            autocomplete: "text",
                                            placeholder: "+998931234567",
                                            required: "",
                                            disabled: _vm.isRegisterSuccess,
                                          },
                                          domProps: {
                                            value: _vm.account.phone,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.account,
                                                "phone",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt-1" },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "block mb-1 text-sm font-medium text-gray-700",
                                attrs: { for: "password" },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("password")) +
                                    "*"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "password",
                                rules: "required|min:1",
                                mode: "eager",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        (_vm.hidden.password
                                          ? "password"
                                          : "text") === "checkbox"
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.account.password,
                                                  expression:
                                                    "account.password",
                                                },
                                              ],
                                              staticClass:
                                                "focus:outline-none appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 sm:text-sm",
                                              class:
                                                errors.length > 0
                                                  ? "border-red-400"
                                                  : _vm.account.password
                                                  ? "border-green-600"
                                                  : "border-gray-300",
                                              attrs: {
                                                name: "password",
                                                autocomplete: "text",
                                                placeholder: _vm.$t("password"),
                                                required: "",
                                                disabled: _vm.isRegisterSuccess,
                                                type: "checkbox",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.account.password
                                                )
                                                  ? _vm._i(
                                                      _vm.account.password,
                                                      null
                                                    ) > -1
                                                  : _vm.account.password,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.account.password,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.account,
                                                          "password",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.account,
                                                          "password",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.account,
                                                      "password",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            })
                                          : (_vm.hidden.password
                                              ? "password"
                                              : "text") === "radio"
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.account.password,
                                                  expression:
                                                    "account.password",
                                                },
                                              ],
                                              staticClass:
                                                "focus:outline-none appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 sm:text-sm",
                                              class:
                                                errors.length > 0
                                                  ? "border-red-400"
                                                  : _vm.account.password
                                                  ? "border-green-600"
                                                  : "border-gray-300",
                                              attrs: {
                                                name: "password",
                                                autocomplete: "text",
                                                placeholder: _vm.$t("password"),
                                                required: "",
                                                disabled: _vm.isRegisterSuccess,
                                                type: "radio",
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.account.password,
                                                  null
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    _vm.account,
                                                    "password",
                                                    null
                                                  )
                                                },
                                              },
                                            })
                                          : _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.account.password,
                                                  expression:
                                                    "account.password",
                                                },
                                              ],
                                              staticClass:
                                                "focus:outline-none appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 sm:text-sm",
                                              class:
                                                errors.length > 0
                                                  ? "border-red-400"
                                                  : _vm.account.password
                                                  ? "border-green-600"
                                                  : "border-gray-300",
                                              attrs: {
                                                name: "password",
                                                autocomplete: "text",
                                                placeholder: _vm.$t("password"),
                                                required: "",
                                                disabled: _vm.isRegisterSuccess,
                                                type: _vm.hidden.password
                                                  ? "password"
                                                  : "text",
                                              },
                                              domProps: {
                                                value: _vm.account.password,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.account,
                                                    "password",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.isEmail
                          ? _c(
                              "div",
                              { staticClass: "mt-1" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "block mb-1 text-sm font-medium text-gray-700",
                                    attrs: { for: "password" },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("confirm-password")) +
                                        "*"
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "confirmPassword",
                                    rules: {
                                      required: true,
                                      samePassword: _vm.account.password,
                                    },
                                    mode: "eager",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            (_vm.hidden.confirm_password
                                              ? "password"
                                              : "text") === "checkbox"
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.confirm_password,
                                                      expression:
                                                        "confirm_password",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "focus:outline-none appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 sm:text-sm",
                                                  class:
                                                    errors.length > 0
                                                      ? "border-red-400"
                                                      : _vm.confirm_password
                                                      ? "border-green-600"
                                                      : "border-gray-300",
                                                  attrs: {
                                                    name: "confirm_password",
                                                    placeholder:
                                                      _vm.$t(
                                                        "confirm-password"
                                                      ),
                                                    required: "",
                                                    disabled:
                                                      _vm.isRegisterSuccess,
                                                    type: "checkbox",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.confirm_password
                                                    )
                                                      ? _vm._i(
                                                          _vm.confirm_password,
                                                          null
                                                        ) > -1
                                                      : _vm.confirm_password,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.confirm_password,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.confirm_password =
                                                              $$a.concat([$$v]))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.confirm_password =
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                ))
                                                        }
                                                      } else {
                                                        _vm.confirm_password =
                                                          $$c
                                                      }
                                                    },
                                                  },
                                                })
                                              : (_vm.hidden.confirm_password
                                                  ? "password"
                                                  : "text") === "radio"
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.confirm_password,
                                                      expression:
                                                        "confirm_password",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "focus:outline-none appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 sm:text-sm",
                                                  class:
                                                    errors.length > 0
                                                      ? "border-red-400"
                                                      : _vm.confirm_password
                                                      ? "border-green-600"
                                                      : "border-gray-300",
                                                  attrs: {
                                                    name: "confirm_password",
                                                    placeholder:
                                                      _vm.$t(
                                                        "confirm-password"
                                                      ),
                                                    required: "",
                                                    disabled:
                                                      _vm.isRegisterSuccess,
                                                    type: "radio",
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.confirm_password,
                                                      null
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      _vm.confirm_password =
                                                        null
                                                    },
                                                  },
                                                })
                                              : _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.confirm_password,
                                                      expression:
                                                        "confirm_password",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "focus:outline-none appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 sm:text-sm",
                                                  class:
                                                    errors.length > 0
                                                      ? "border-red-400"
                                                      : _vm.confirm_password
                                                      ? "border-green-600"
                                                      : "border-gray-300",
                                                  attrs: {
                                                    name: "confirm_password",
                                                    placeholder:
                                                      _vm.$t(
                                                        "confirm-password"
                                                      ),
                                                    required: "",
                                                    disabled:
                                                      _vm.isRegisterSuccess,
                                                    type: _vm.hidden
                                                      .confirm_password
                                                      ? "password"
                                                      : "text",
                                                  },
                                                  domProps: {
                                                    value: _vm.confirm_password,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.confirm_password =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isPhoneConfirmPending
                          ? _c(
                              "div",
                              { staticClass: "mt-1" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "block mb-1 text-sm font-medium text-gray-700",
                                    attrs: { for: "otp" },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("enter-confirm-code"))
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "otp",
                                    rules: "required|min:4|max:4",
                                    mode: "eager",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "relative flex items-stretch flex-grow focus-within:z-10",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.otp,
                                                      expression: "otp",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "focus:outline-none appearance-none block w-full px-3 py-2 border rounded-none rounded-l-md shadow-sm placeholder-gray-400 sm:text-sm",
                                                  class:
                                                    errors.length > 0
                                                      ? "border-red-400"
                                                      : _vm.otp
                                                      ? "border-green-600"
                                                      : "border-gray-300",
                                                  attrs: {
                                                    name: "otp",
                                                    type: "text",
                                                    autocomplete: "text",
                                                    placeholder:
                                                      _vm.$t(
                                                        "enter-confirm-code"
                                                      ),
                                                    required: "",
                                                  },
                                                  domProps: { value: _vm.otp },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.otp =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border text-sm font-medium rounded-r-md",
                                                    class: invalid
                                                      ? "text-gray-700 bg-gray-100 hover:bg-gray-200 border-gray-100 hover:border-gray-200"
                                                      : "text-white focus:outline-none text-sm bg-green-700 p-3",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: _vm.confirmOtp,
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("confirm")
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "md:col-span-1",
                                            }),
                                            _vm._v(" "),
                                            _vm.isPhoneConfirmPending
                                              ? _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-sm text-gray-500 mb-2",
                                                      class: [
                                                        _vm.timer === "on"
                                                          ? ""
                                                          : "hidden",
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "you-can-resend-code-after"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                      _vm.timer === "on"
                                                        ? _c("countdown", {
                                                            staticClass:
                                                              "text-primary font-semibold",
                                                            attrs: {
                                                              "end-time":
                                                                _vm.timerMinutes,
                                                            },
                                                            on: {
                                                              finish:
                                                                _vm.onCountdownEnd,
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "process",
                                                                  fn: function (
                                                                    scope
                                                                  ) {
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "ml-1",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              scope
                                                                                .timeObj
                                                                                .m
                                                                            ) +
                                                                              " : " +
                                                                              _vm._s(
                                                                                scope
                                                                                  .timeObj
                                                                                  .s
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.timer === "off"
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-sm ml-1 focus:outline-none cursor-pointer",
                                                          class: [
                                                            _vm.timer === "off"
                                                              ? "text-yellow-600"
                                                              : "text-gray-400",
                                                          ],
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.resendCode()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                  " +
                                                              _vm._s(
                                                                _vm.$t("resend")
                                                              ) +
                                                              "\n                "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ])
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isPhoneConfirmPending && !_vm.isRegisterSuccess
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "md:col-span-2 col-span-1 text-center px-2.5 py-1.5 border borxder-transparent font-medium rounded",
                                class: invalid
                                  ? "text-gray-700 bg-gray-100 hover:bg-gray-200 border-gray-100 hover:border-gray-200 focus:ring-gray-500"
                                  : "text-white focus:outline-none text-sm bg-green-700 p-3",
                                attrs: { type: "submit", disabled: invalid },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("submit")) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }