var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "group w-full hover:shadow-md shadow-sm border rounded-md relative",
    },
    [
      _c(
        "div",
        { staticClass: "flex items-center w-full rounded-md" },
        [
          _c(
            "nuxt-link",
            {
              staticClass:
                "relative group cursor-pointer lg:h-96 h-72 w-full rounded-md bg-cover duration-500",
              style:
                _vm.data.attributes.thumbnail || _vm.data.attributes.image
                  ? `background-image: url(${_vm.$tools.getFileUrl(
                      _vm.data.attributes.thumbnail
                        ? _vm.data.attributes.thumbnail
                        : _vm.data.attributes.image
                    )})`
                  : "background-image: url(https://caep.org/blog/wp-content/uploads/2020/04/TractorFarm_Lead.jpg);",
              attrs: {
                to: {
                  path: _vm.localePath(`/agri-finance/${_vm.data.id}`),
                  query: { id: _vm.data.id },
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "absolute inset-0 z-10 group-hover:opacity-100 opacity-0 duration-500",
                },
                [
                  _c("img", {
                    staticClass: "h-96 w-full",
                    attrs: { src: require("assets/images/news.jpg"), alt: "" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "absolute inset-0 bg-gray-700 opacity-70 w-full rounded-md h-full",
              }),
              _vm._v(" "),
              _c("div", { staticClass: "absolute top-5 left-5 right-5 z-20" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-xs rounded-md text-green-800 w-20 flex justify-center bg-green-50 py-1.5 px-7 font-medium",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.data.attributes.servicecategory.data
                            ? _vm.data.attributes.servicecategory.data
                                .attributes.name
                            : ""
                        ) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "text-white group-hover:text-green-700 font-medium line-clamp-1 text-lg my-6 z-2 duration-500",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.data.attributes.title) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "text-white group-hover:text-gray-500 text-sm my-6 z-20 line-clamp-4",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.data.attributes.description) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "border border-white group-hover:border-gray-100 absolute inset-x-0 bottom-16 z-20 duration-500",
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "absolute left-5 text-sm bottom-5 group-hover:text-gray-500 text-white z-20 duration-500",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$tools.getDate(_vm.data.attributes.createdAt)
                      ) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "absolute right-5 text-sm bottom-5 group-hover:text-gray-500 text-white z-20 duration-500",
                },
                [_vm._v("\n          " + _vm._s(_vm.$t("read")) + "\n        ")]
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }