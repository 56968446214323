var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "price relative" },
    [
      _c(
        "swiper",
        {
          ref: "swiper",
          staticClass: "swiper",
          attrs: {
            options: _vm.priceOption,
            autoplay: { delay: 2000, disableOnIteraction: false },
            pagination: { clickable: true },
          },
        },
        [
          _c(
            "client-only",
            _vm._l(_vm.prices, function (price, index) {
              return _c(
                "swiper-slide",
                {
                  key: index,
                  staticClass:
                    "flex bg-white rounded-md space-x-3 z-0 pb-2 transition duration-500",
                  attrs: { "data-swiper-autoplay": "2000" },
                },
                [_c("prices", { attrs: { data: price } })],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "absolute group top-1/2 lg:flex hidden justify-between -inset-x-9",
        },
        [
          _c("div", {
            staticClass: "swiper-button-prev btn shadow-md opacity-0 left-9",
            attrs: { slot: "button-prev" },
            on: {
              click: function ($event) {
                return _vm.prev()
              },
            },
            slot: "button-prev",
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "swiper-button-next btn shadow-md opacity-0 -right-9",
            attrs: { slot: "button-next" },
            on: {
              click: function ($event) {
                return _vm.next()
              },
            },
            slot: "button-next",
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }