var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "bg-green-900 relative" }, [
    _c(
      "div",
      {
        staticClass:
          "max-w-6xl mx-auto grid lg:grid-cols-4 grid-cols-1 sm:px-6 lg:px-8 xl:px-0 px-4 gap-10 text-white pt-10 pb-10",
      },
      [
        _c("div", { staticClass: "block" }, [
          _vm.items.attributes && _vm.items.attributes.title
            ? _c("div", { staticClass: "font-semibold text-base pb-6" }, [
                _vm._v(" " + _vm._s(_vm.items.attributes.title)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.items.attributes && _vm.items.attributes.description
            ? _c("div", { staticClass: "text-xs leading-5" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.items.attributes.description) +
                    "\n        "
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "block" }, [
          _c("div", { staticClass: "grid lg:justify-end justify-start" }, [
            _c("div", { staticClass: "font-semibold text-base pb-6" }, [
              _vm._v(_vm._s(_vm.$t("contacts"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-xs space-y-3" }, [
              _vm.items.attributes && _vm.items.attributes.address
                ? _c("div", { staticClass: "leading-5" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.items.attributes.address) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.items.attributes && _vm.items.attributes.phone
                ? _c("p", [_vm._v(_vm._s(_vm.items.attributes.phone))])
                : _vm._e(),
              _vm._v(" "),
              _vm.items.attributes && _vm.items.attributes.email
                ? _c("p", [_vm._v(_vm._s(_vm.items.attributes.email))])
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "grid lg:justify-end justify-start" }, [
          _c("div", { staticClass: "font-semibold text-base pb-5" }, [
            _vm._v(_vm._s(_vm.$t("useful-links"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid grid-cols-2 text-xs gap-3" },
            _vm._l(_vm.footer, function (item, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "flex items-center",
                      attrs: { to: { path: _vm.localePath(item.route) } },
                    },
                    [
                      _c("i", {
                        staticClass: "bx bx-chevron-right text-white text-lg",
                      }),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.$t(item.title)))]),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "block" }, [
          _c("div", { staticClass: "grid jlg:justify-end justify-start" }, [
            _c("div", { staticClass: "font-semibold text-base pb-6" }, [
              _vm._v(_vm._s(_vm.$t("services"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "space-y-3 text-xs" },
              _vm._l(_vm.services, function (service, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "flex items-center gap-3",
                        attrs: { to: { path: _vm.localePath(service.route) } },
                      },
                      [_c("p", [_vm._v(_vm._s(_vm.$t(service.title)))])]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "border-t border-white py-5" }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "flex items-center justify-center space-x-5 text-white text-sm pb-10",
      },
      [
        _c("div", [_vm._v(_vm._s(_vm.$t("privacy-policy")))]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.$t("sitemap")))]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.$t("terms-of-use")))]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "flex items-center justify-start space-x-4" },
      [
        _c(
          "a",
          { attrs: { href: "https://t.me/jasilawil", target: "_blank" } },
          [
            _c("img", {
              attrs: {
                src: "https://icons.iconarchive.com/icons/froyoshark/enkel/32/Telegram-icon.png",
                width: "32",
                height: "32",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: "https://twitter.com/GggiUzbekistan",
              target: "_blank",
            },
          },
          [
            _c("img", {
              staticClass: "w-8",
              attrs: { src: require("assets/images/socials/twitter.png") },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.linkedin.com/company/94823471",
              target: "_blank",
            },
          },
          [
            _c("img", {
              staticClass: "w-8",
              attrs: { src: require("assets/images/socials/linkedin.png") },
            }),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }