var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeMenu,
          expression: "closeMenu",
        },
      ],
      staticClass: "relative flex flex-row text-left text-gray-800",
    },
    [
      _c("div", [_c("span", [_vm._t("locale")], 2)]),
      _vm._v(" "),
      _c("div", [
        _c(
          "span",
          {},
          [_vm._t("trigger", null, { onClick: _vm.toggleMenu })],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class": "transition duration-100 ease-out",
            "enter-class": "transform scale-95 opacity-0",
            "enter-to-class": "transform scale-100 opacity-100",
            "leave-active-class": "transition duration-75 ease-in",
            "leave-class": "transform scale-100 opacity-100",
            "leave-to-class": "transform scale-95 opacity-0",
          },
        },
        [
          _vm.isMenuOpen
            ? _c(
                "div",
                {
                  staticClass:
                    "absolute z-40 top-11 right-0 overflow-hidden text-sm rounded-md custom-shadow",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "bg-white rounded-md ring-1 ring-black ring-opacity-5",
                      attrs: {
                        role: "menu",
                        "aria-orientation": "vertical",
                        "aria-labelledby": "options-menu",
                      },
                    },
                    [_vm._t("content", null, { closeMenu: _vm.closeMenu })],
                    2
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }